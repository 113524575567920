import axios from 'axios';

const intialState = [];
// const API = 'http://localhost:3000/';
const API = 'https://13.127.239.26/';

const GET_USER = 'GET_USER';
const EDIT_USER = 'EDIT_USER';
const DELETE_USER = 'DELETE_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const VERIFY_PHONE = 'VERIFY_PHONE';

// FETCH USER
const fetchuser = (token) => (dispatch) => {
  axios.post(API, { token }, { withCredentials: true }).then((res) => {
    const user = res.data;
    dispatch({
      type: GET_USER,
      user,
    });
  });
};

// EDIT USERS
const edituser = (editedUser) => (dispatch) => {
  axios.post('https://13.127.239.26/edit', { editedUser }, { withCredentials: true }).then((res) => {
  // axios.post('http://localhost:3000/edit', { editedUser }, { withCredentials: true }).then((res) => {
    const user = res.data;
    // console.log(res.data);
    dispatch({
      type: EDIT_USER,
      user,
    });
  });
};

// DELETE USERS
const deleteuser = (token) => (dispatch) => {
  axios.post('https://13.127.239.26/delete', { token }, { withCredentials: true }).then(() => {
  // axios.post('http://localhost:3000/delete', { token }, { withCredentials: true }).then(() => {
    const user = [];
    dispatch({
      type: DELETE_USER,
      user,
    });
  });
};

// VERIFY USER'S PHONE
const verifyUserPhone = (token) => (dispatch) => {
  axios.post('https://13.127.239.26/verify_phone', { token }, { withCredentials: true }).then((res) => {
  // axios.post('http://localhost:3000/verify_phone', { token }, { withCredentials: true }).then((res) => {
    const user = res.data;
    dispatch({
      type: VERIFY_PHONE,
      user,
    });
  });
};

// Log Out users
const logoutuser = () => (dispatch) => {
  const user = [];
  dispatch({
    type: LOGOUT_USER,
    user,
  });
};

// eslint-disable-next-line default-param-last
const userReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_USER:
      return [
        action.user,
      ];
    case EDIT_USER:
      return [
        action.user,
      ];
    case DELETE_USER:
      return [
        action.user,
      ];
    case LOGOUT_USER:
      return [
        action.user,
      ];
    case VERIFY_PHONE:
      return [
        action.user,
      ];
    default:
      return state;
  }
};

export default userReducer;
export {
  fetchuser, edituser, logoutuser, deleteuser, verifyUserPhone,
};
