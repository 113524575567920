// /* eslint-disable */
import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import ChatBot from 'react-simple-chatbot';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from 'styled-components';
import { Suspense, lazy } from 'react';
import './App.css';
// import { steps, theme, config } from './components/Chatbot/Chatbot';
import { theme } from './components/Chatbot/Chatbot';
// import Tourism from './components/Tourism/Tourism';

const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Home = lazy(() => import('./components/Home/Home'));
const About = lazy(() => import('./components/About/About'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Destinations = lazy(() => import('./components/Destinations/Destinations'));
const Packages = lazy(() => import('./components/Packages/Packages'));
const LogIn = lazy(() => import('./components/SignUp/LogIn'));
const MyAccount = lazy(() => import('./components/MyAccount/MyAccount'));
const Orders = lazy(() => import('./components/Orders/Orders'));
const CreateAC = lazy(() => import('./components/CreateAC/CreateAC'));
const Agent = lazy(() => import('./components/Agent/Agent'));
const Cab = lazy(() => import('./components/Cab/Cab'));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<h5>Loading....</h5>}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/holidays" element={<Destinations />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/cab" element={<Cab />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/create-account" element={<CreateAC />} />
            <Route path="/agents" element={<Agent />} />
          </Routes>
          <Footer />
          {/* <Tourism /> */}
        </BrowserRouter>
      </Suspense>
      <ThemeProvider theme={theme}>
        {/* <ChatBot
          headerTitle="GTG Holiday"
        steps={steps} */}
        {/* recognitionEnable
        eslint-disable-next-line react/jsx-props-no-spreading */}
        {/* {...config} */}

        {/* /> */}
      </ThemeProvider>
      <a
        aria-label="whatsApp Logo"
        href="https://wa.me/919903678546"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon" />
      </a>
    </div>
  );
}

export default App;
