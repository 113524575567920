import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import packageReducer from './Packages/packagesRedux';
import userReducer from './User/userRedux';

const rootReducer = combineReducers({
  packages: packageReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
