// /* eslint-disable */
function BotRedirect(prop) {
  const { url } = prop;
  return (
    <div>
      <a href={url} target="_blank" rel="noreferrer">
        packages
      </a>
    </div>
  );
}

export const steps = [
  {
    id: '0',
    message: 'Greetings from GTG Holiday!',

    // This calls the next id
    // i.e. id 1 in this case
    trigger: '1',
  }, {
    id: '1',

    // This message appears in
    // the bot chat bubble
    message: 'Please write your Name',
    trigger: '2',
  }, {
    id: '2',
    // Here we want the user
    // to enter input
    user: true,
    trigger: '3',
  }, {
    id: '3',
    message: ' hi {previousValue}, how can I help you?',
    trigger: 4,
  }, {
    id: '4',
    options: [
      // When we need to show a number of
      // options to choose we create alist
      // like this
      { value: 1, label: 'View packages', trigger: '5' },
      { value: 2, label: 'Talk to our agent' },

    ],
    end: true,
  }, {
    id: '5',
    component: (
      <>
        <h1>Hello</h1>
        <BotRedirect
          message="See all packages in this page"
          url="<https://gtgholiday.com/packages>"
        />
      </>
    ),
    // trigger: '2',
  },
];

// Creating our own theme
export const theme = {
  background: '#84f493C',
  fontFamily: 'Roboto',
  // eslint-disable-next-line no-dupe-keys
  background: '#e2f8cb',
  headerBgColor: 'red',
  headerFontColor: 'white',
  headerFontSize: '20px',
  botBubbleColor: '#094ee3',
  // eslint-disable-next-line no-dupe-keys
  headerFontColor: 'white',
  botFontColor: 'white',
  userBubbleColor: '#074f11',
  userFontColor: 'white',
};

// Set some properties of the bot
export const config = {
  // botAvatar: "img.png",
  floating: true,
};

//   export steps, theme, config;
